import { writable } from 'svelte/store';
import { goto } from '$app/navigation';

interface Crumb {
  url: URL;
  title: string;
}

const breadcrumbs = writable<Crumb[]>([]);

let currentCrumb: Crumb | undefined;

/**
 * The old currentCrumb gets added to the breadcrumbs, and the
 * new crumb becomes the new currentCrumb.
 */
export function addBreadcrumb(url: URL, title: string = 'uTheory') {
  if (currentCrumb) {
    const pathMatch = url.pathname === currentCrumb.url.pathname;
    const searchMatch = url.search === currentCrumb.url.search;

    // If it's an exact match, don't add it
    if (pathMatch && searchMatch) {
      return;
    }

    // If only the search has changed, update the current crumb
    if (pathMatch && !searchMatch) {
      currentCrumb = {
        url,
        title,
      };
      return;
    }
  }
  breadcrumbs.update((crumbs) => {
    const priorCrumb = currentCrumb;
    currentCrumb = { url, title };
    if (priorCrumb) return [...crumbs, priorCrumb];
    return crumbs;
  });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
let _crumbSubscription: Crumb[];
breadcrumbs.subscribe((crumbs) => {
  _crumbSubscription = crumbs;
});

export function goBack() {
  const priorCrumb = _crumbSubscription.slice(-1)[0];

  if (priorCrumb) {
    // Drop the prior path because it'll be re-added when
    // we go to it, and make the current path undefined,
    // so that it doesn't get added:
    breadcrumbs.update((crumbs) => {
      currentCrumb = undefined;
      const newCrumbs = crumbs.slice(0, -1);
      return newCrumbs;
    });
    goto(priorCrumb.url.toString());
    return true;
  }
  return false;
}
